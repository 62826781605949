@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
@include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;

$light-primary: scale-color($primary-color, $lightness: 50%);
$dark-primary: scale-color($primary-color, $lightness: -50%);

.page-header {
    background: $black;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100%;
    overflow: hidden;
    position: relative;
    z-index: 199;

    .title {
        color: $white;
        letter-spacing: 2px;
    }

    .secondary_title {
        font-family: Times ;
        color: $primary-color;
        letter-spacing: 2px;
    }

    .content {
        @include mui-animation(fade);
        animation-duration: 1s;
        position: relative;
        z-index: 300;
        color: $primary-color;
        display:flex; 
        align-items:center; 
        justify-content:center;
        height: 200px;
        small {
            color: $white;
        }
    }

    .bg {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: gray;
        background-position: center;
        background-size: cover;
        z-index: 199;
        left: 0;
        top: 0;
        opacity: 0.75;
    }
}

.title-bar {
    position: relative;
    z-index: 201;
    padding: $global-padding;

    &.append {
        .buttons {
            text-align: right;
            color: $white;

            a,
            button {
                color: $white;
                margin: 0 $global-margin/3;
                font-size: $global-font-size*1.3;
            }
        }
    }
}

.top-bar {
    &.append-admin {
        background-color: $black;

        .menu-text {
            color: $white;
        }
    }

    &.append {
        position: absolute;
        left: 0px;
        width: 100%;
        z-index: 200;
        padding: $global-padding*2 0;

        .top-bar-logo {
            flex: 0 0 auto;
            margin: 0 $global-margin;

            img {
                height: 45px;
            }
        }

        .top-bar-left {
            flex: 1 1 0px;

            .menu {
                justify-content: flex-end;
            }
        }

        .top-bar-right {
            flex: 1 1 0px;
        }

        ul {
            background-color: transparent;
        }

        a {
            color: $white;
            position: relative;
            overflow: hidden;

            .cht {
                position: absolute;
                bottom: -100%;
                left: 0;
                z-index: 2;
                width: 100%;
                height: 100%;
                -webkit-transition: .3s;
                transition: .3s;
                opacity: 1;
                padding: 0.7rem 1rem;
                display: block;
                text-align: center;
            }

            .en {
                -webkit-transition: .3s;
                transition: .3s;
                display: block;
                text-align: center;
            }

            &:hover {
                .cht {
                    bottom: 0;
                    left: 0;
                    border-bottom: 1px solid $alert-color;
                    color: $primary-color;
                }

                .en {
                    opacity: 0;
                }
            }
        }

        .dropdown.menu {
            >li.is-dropdown-submenu-parent {
                >a {
                    &::after {
                        border-color: $white transparent transparent;
                        ;
                    }
                }
            }
        }

        .social {
            position: absolute;
            right: 8px;
            z-index: 201;
            font-size: $global-font-size*1.2;

            a {
                margin: 0 $global-margin/2;
            }
        }
    }
}

.footer {
    background: $black;
    padding: $global-padding*2 0;

    .title {
        height: 40px;
        margin-bottom: $global-margin;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;

        .logo {
            height: 100%;
        }

        strong {
            color: $dark-gray;
        }
    }

    .about {
        line-height: $global-lineheight*1.3;
        color: $light-gray;
    }

    .content {
        color: $light-gray;
        line-height: $global-lineheight*2;

        i {
            padding: $global-padding/2;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: $primary-color;
            color: $black;
            border-radius: 50%;
            width: 36px;
            height: 36px;
            margin-right: $global-margin/2;
        }
    }

    .connect {
        a {
            margin-right: $global-margin/2;

            i {
                font-size: $global-font-size*1.8;
            }
        }
    }
}

.menu {
    &.snip1198 {
        text-align: center;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 1px;
    }

    &.snip1198 * {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-transition: all 0.35s ease;
        transition: all 0.35s ease;
    }

    &.snip1198 li {
        display: inline-block;
        list-style: outside none none;
        margin: 0.4em 0.8em;
        padding: 0;
    }

    &.snip1198 a {
        padding: 0 0.7em;
        color: $dark-gray;
        position: relative;
        text-decoration: none;
        display: inline-block;
    }

    &.snip1198 a:before, .a.active {
        position: absolute;
        content: '';
        -webkit-transition: all 0.35s ease;
        transition: all 0.35s ease;
        opacity: 0;
        left: 15%;
        right: 15%;
        top: 0;
        bottom: 0;
        border-left: 3px solid $primary-color;
        border-right: 3px solid $primary-color;
    }

    &.snip1198 a:hover,
    &.snip1198 .current a {
        color: $black;
    }

    &.snip1198 a:hover:before,
    &.snip1198 .current a:before {
        opacity: 1;
        left: 0;
        right: 0;
    }
}


.orbit {
    &.append {

        .orbit-caption {
            position: unset;
            padding: unset;
            background: unset;
            width: 50%;
            color: $white;
            font-weight: bold;
            text-align: center;
            font-weight: bold;
            @include mui-animation(slide($direction: left, $amount: 120%, $state: in));
            animation-duration: 1s;
        }

        .img {
            height: 100vh;
            background-size: cover;
            background-position: center;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .orbit-controls {
            .orbit-previous {
                left: 16px;
            }

            .orbit-next {
                right: 16px;
            }
        }

        .orbit-img-bullets {
            position: absolute; 
            left: 0px; 
            bottom: 0px; 
            width: 100%; 
            padding:1rem;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            background-color: rgba($black, 0.65);
            button {
                padding: $global-padding/4;
                display: inline-block;
                transition: .3s;

                img {
                    height: 8vh;
                    filter: grayscale(80%);
                }

                &:hover {
                    cursor: pointer;
                }
            }

            button {
                &.is-active {
                    img {
                        filter: unset;
                    }
                }
            }
        }

    }

    &.append-work {

        .orbit-controls {
            .orbit-previous {
                left: 16px;
                color: $light-gray;
            }

            .orbit-next {
                right: 16px;
                color: $light-gray;
            }
        }

        .orbit-figure {
            height: 70vh;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                max-height: 100%;
                width: unset;
                max-width: 100%;
            }
        }

        .orbit-container {
            margin-bottom: $global-margin;
        }

        .orbit-img-bullets {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            button {
                padding: $global-padding/4;
                display: inline-block;
                transition: .3s;

                img {
                    height: 8vh;
                    filter: grayscale(80%);
                }

                &:hover {
                    cursor: pointer;
                }
            }

            button {
                &.is-active {
                    img {
                        filter: unset;
                    }
                }
            }
        }
    }
}

.area {

    header {
        margin-bottom: $global-margin*2;
        .title {
            margin-bottom: 0;
        }
        .secondary_title {
            font-family: 'Times New Roman', Times, serif;
            color: scale-color($primary-color, $lightness: -50%);
        }
        &.type-1 {
            .title {
                text-align: right
            }
            .secondary_title {
                text-align: right
            }
        }
    }

    &:nth-child(odd) {
        background-color: scale-color($primary-color, $lightness: 90%);
    }

    &.slide {
        background-color: #3e3a39;
    }

    &.work {
        .description {
            line-height: $global-lineheight*1.3;
        }
    }

}

.address {
    .item {
        margin-bottom: $global-margin;

        &:last-child {
            margin-bottom: 0;
        }

        span.icon {
            width: 80px;
            height: 80px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            border: 1px solid $primary-color;
            color: $primary-color;
            vertical-align: middle;
            border-radius: 50%;
        }
    }
}

.info-block-horizontal {
    .item {
        .title {
            margin-bottom: 0;
        }
        .secondary_title {
            font-family: 'Times New Roman', Times, serif;
            color: scale-color($primary-color, $lightness: -50%);
        }
        .image {
            @include mui-animation(fade(in));
            animation-duration: 1s;
            position: relative;
            z-index: 100;
        }

        .content {
            .block {
                @include mui-animation(slide($direction: left, $amount: 120%));
                animation-duration: 1.5s;
                // color: $white;
                // position: relative;
                z-index: 101;
                text-align: center;
                // border: 1px solid $dark-primary; 
                background: $white;
                padding: $global-padding*2;
                // margin: 0 0 0 -32px;
                border-radius: $global-radius;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

.info-block-vertical {
    .item {
        img {
            position: relative;
            z-index: 100;
            @include mui-animation(fade(in));
            animation-duration: 1s;
        }

        .block {
            @include mui-animation(slide($direction: bottom, $amount: 120%));
            animation-duration: 1.5s;
            text-align: center;
            border-radius: $global-radius;
            z-index: 101;
            padding-top: $global-padding*2;
            // border: 1px solid $dark-primary;
        }
    }
}

.info-block-cover {
    figure {
        @include mui-animation(fade(in));
        animation-duration: 1s;
        position: relative;
        overflow: hidden;
        width: 100%;

        %info {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            -webkit-transition: .3s;
            transition: .3s;
            opacity: 1;
            padding: 0 $global-position;

            div {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                // border: 1px solid $dark-primary; 
                background: rgba($dark-primary, 0.65);
                color: $white;
                height: 100%;
                width: 100%;
                border-radius: $global-radius;
            }
        }

        img {
            -webkit-transition: .3s;
            transition: .3s;
        }

        .cover-title {
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: $white;
            font-weight: bold;
            @extend %info;
        }

        figcaption {
            bottom: -100%;
            left: 0;
            opacity: 0;
            @extend %info;
        }

        &:hover {
            overflow: visible;

            .cover-title {
                opacity: 0;
                // background: rgba($primary-color, 0.65);
            }

            figcaption {
                bottom: -16px;
                left: 0;
                opacity: 1;
                // background: rgba($primary-color, 0.65);
            }

            img {
                filter: grayscale(80%);
            }
        }
    }
}

.info-flow-horizontal {
    .item {
        text-align: center;
        .info {
            .title {
                margin-bottom: 0;
            }
            .secondary_title {
                font-family: 'Times New Roman', Times, serif;
                color: scale-color($primary-color, $lightness: -50%);
            }
        }
        .process {
            
            position: relative;
            .line {
                position: absolute;
                top: 50%;
                height: 3px;
                width: 100%;
                background-color: $light-primary;
                z-index: 10;
            }

            img {
                position: relative;
                max-width: 84px;
                height: auto;
                @include mui-animation(fade(in));
                animation-duration: 1s;
                z-index: 20;
            }
        }
    }
}

.planning-works {
    figure {
        @include mui-animation(slide($direction: up, $amount: 120%));
        animation-duration: 1s;
        position: relative;
        overflow: hidden;
        width: 100%;
        color: $white;
        box-shadow: 0 12px 10px -6px rgba(0, 0, 0, .3);

        figcaption {
            position: absolute;
            bottom: -8px;
            left: 0;
            z-index: 2;
            width: auto;
            // border: 1px solid $dark-primary; 
            -webkit-transition: .3s;
            transition: .3s;
            padding: $global-padding;
            margin: 0 $global-margin;
            border-radius: $global-radius;
            .secondary_title {
                display: none;
                transition: .3s;
            }
        }
    }

    figure {
        &:hover {
            img {
                filter: grayscale(65%);
            }

            overflow: visible;

            figcaption {
                background: rgba($dark-primary, 0.65);
                bottom: -5%;
                .secondary_title {
                    display: block;
                }
            }
        }
    }
}

.contact {
    .form {
        input,
        textarea {
            border-width: 0 0 1px 0;
            border-color: $primary-color;
            border-style: dotted;
            background-color: transparent;
            box-shadow: none;
            padding: $global-padding 0;
            border-radius: 0;
    
            &:focus {
                background-color: transparent;
                border-width: 0 0 1px 0;
                border-color: scale-color($primary-color, $lightness: -10%);
                box-shadow: none;
            }
        }
    }
}


table {
    &.cht-nowrap {
        white-space:nowrap;
    }
    &.append {
        .sort {
            width: auto;
            text-align: center;
        }
    }
}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .title-bar {
        &.append {
            display: block !important;

            .buttons {
                i {
                    font-size: $global-font-size*1.5;
                }
            }
        }
    }

    .top-bar {
        &.append {
            padding: $global-padding;

            .top-bar-left,
            .top-bar-right {
                flex: 1 1 auto;
            }
        }
    }

    .orbit {
        &.append-work {
            .orbit-figure {
                height: 50vh;
            }

            .orbit-img-bullets {
                padding: $global-padding/2;

                button {
                    img {
                        height: 7vh;
                    }
                }
            }
        }
    }
}

/* Small only */
@media screen and (max-width: 39.9375em) {
    .title-bar {
        &.append {
            display: block !important;
        }
    }

    .top-bar {
        &.append {
            padding: 0;

            .top-bar-left,
            .top-bar-right {
                flex: 1 1 auto;
            }
        }

        .top-bar-left,
        .top-bar-right,
        ul,
        li {
            width: 100%;
        }

        a {
            text-align: center;
            font-size: $global-font-size*1.2;
            border-bottom: 1px dotted rgba($white, 0.65);

            &:hover {
                .cht {
                    padding: $global-padding*1.2 0;
                    border-bottom: none !important;
                }
            }
        }

        .dropdown.menu>li>a {
            padding: $global-padding*1.2 0;
        }

        .top-bar-right {
            ul {
                li {
                    &:last-child {
                        a {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }

    .area {
        &.about {
            .item {
                &:nth-child(odd) {
                    .image {
                        order: 0;
                    }
                }
            }
        }
    }

    .address {
        margin-bottom: $global-margin*2 !important;
    }

    .orbit {
        &.append-work {
            .orbit-figure {
                height: 30vh;
            }

            .orbit-img-bullets {
                button {
                    img {
                        height: 5vh;
                    }
                }
            }
        }
    }

    .contact {
        .form {
            order: 2;
        }
        .map {
            order: 1;
            margin-bottom: $global-margin*2;
        }
    }

    .info-flow-horizontal {
        .item {
            text-align: center;
            .info {
                padding-left: $global-padding;
                text-align: left;
            }
            .process {
                position: relative;
                .line {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    height: 100%;
                    width: 3px;
                    background-color: $light-primary;
                    z-index: 10;
                }
                img {
                    margin: 0 ;
                }
            }
        }
    }
    
}

.login {
    background: rgb(243, 225, 174);
    /* Old browsers */
    background: -moz-linear-gradient(-45deg, rgba(243, 225, 174, 1) 0%, rgba(230, 195, 92, 1) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, rgba(243, 225, 174, 1) 0%, rgba(230, 195, 92, 1) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, rgba(243, 225, 174, 1) 0%, rgba(230, 195, 92, 1) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3e1ae', endColorstr='#e6c35c', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
}